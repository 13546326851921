@import 'variables';
@import 'mixins';

$fonts: (
  'Interstate' 300 'Interstate-WGL-Light',
  'Interstate' 400 'Interstate-WGL-Regular',
  'Interstate' 700 'Interstate-WGL-Bold'
);

@each $family, $weight, $file in $fonts {
  @font-face {
    font-family: $family;
    font-weight: $weight;
    src: url('../assets/fonts/#{$file}.eot');
    src: url('../assets/fonts/#{$file}.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/#{$file}.woff2') format('woff2'),
      url('../assets/fonts/#{$file}.woff') format('woff'),
      url('../assets/fonts/#{$file}.otf') format('opentype');
  }
}

@font-face {
  font-family: 'FF Market Pro';
  src: url('../assets/fonts/FF-Market-Pro-Bold.woff2') format('woff2'),
    url('../assets/fonts/FF-Market-Pro-Bold.woff') format('woff');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  background-color: rgba($color-primary, 0.25);
}

body {
  background-color: $color-secondary;
  font-family: 'Interstate', sans-serif;
  font-size: 20px;
  color: $color-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 64px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

.container-page {
  padding: 48px 80px;
}

.base-button {
  position: relative;
  padding: 8px 32px 8px 16px;
  background-color: $color-primary;
  border: none;
  border-radius: 12px 0;
  font-family: $font-primary;
  font-size: 18px;
  font-weight: bold;
  color: $color-secondary;
  text-decoration: none;
  transition: background-color 0.1s;
  outline: none;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    width: 8px;
    height: 8px;
    display: block;
    border-right: 2px solid $color-secondary;
    border-bottom: 2px solid $color-secondary;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    background-color: lighten($color-primary, 2.5%);
  }

  &:active {
    background-color: darken($color-primary, 2.5%);
  }
}

.primary {
  color: $color-primary;
}

@include mobile {
  h2 {
    font-size: 40px;
  }

  .container-page {
    padding: 40px 20px;
  }
}

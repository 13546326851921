@import 'src/styles/variables.scss';

.game-orientation-warning {
  position: absolute;
  top: 50%;
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: $color-white;
  transform: translateY(-50%);

  svg {
    margin-bottom: 20px;

    & > g {
      fill: $color-white;
      stroke: $color-white;
    }
  }
}

@import 'src/styles/variables.scss';

.type-product {
  display: block;
  height: 320px;
  background-color: $color-primary;
  border-radius: 10px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  overflow: hidden;

  img {
    width: 100%;
    height: 224px;
    object-fit: cover;
    object-position: center;
  }

  span {
    display: block;
    padding: 20px 0;
    color: $color-secondary;
    text-align: center;
  }
}

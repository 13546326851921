@import 'src/styles/variables.scss';

.pie-timer {
  position: relative;
  display: inline-block;
  width: 160px;
  height: 160px;

  * {
    box-sizing: content-box;
  }

  &__placeholder,
  &__shadow,
  &__left,
  &__right {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -80px;
    margin-top: -80px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }

  &__placeholder {
    border: 10px solid $color-primary;
  }

  &__shadow {
    border: 10px solid $color-pink;
  }

  &__left,
  &__right {
    border: 10px solid $color-primary;
    border-left-color: transparent;
    border-top-color: transparent;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }

  &__left {
    left: 0;
    animation-name: timer-slide;
    animation-timing-function: ease-in;
    z-index: 5;
  }

  &__right {
    animation-name: timer-toggle;
    animation-timing-function: ease-out;
  }

  &__frame {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 50%;
    overflow: hidden;
  }
}

@keyframes timer-slide {
  0% {
    transform: rotate(-225deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}

@keyframes timer-toggle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    transform: rotate(-45deg);
    opacity: 1;
  }
  75% {
    border-top-color: transparent;
  }
  76% {
    border-top-color: $color-primary;
  }
  100% {
    transform: rotate(135deg);
    opacity: 1;
    border-top-color: $color-primary;
  }
}

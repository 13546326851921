.game-controller {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__tick {
    position: absolute;
    bottom: -5%;
    left: 50%;
    height: 30%;
    transform: translateX(-50%);
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

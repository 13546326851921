@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

nav {
  display: flex;
  align-items: center;
  min-width: 100%;
  min-height: 80px;
  background-color: $color-secondary;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-weight: 700;
  z-index: 10;

  a {
    padding: 0 20px;
    line-height: 80px;
    color: $color-primary;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &.active {
      span {
        border-color: $color-primary;
      }
    }

    span {
      padding-bottom: 2px;
      border-bottom: 2px solid transparent;
    }
  }

  .logo {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    svg {
      margin: 16px 20px 24px;
    }
  }

  .language-switcher-link {
    display: inline-block;
    padding: 0 10px;
    line-height: 80px;
    text-transform: uppercase;
    cursor: pointer;

    span {
      padding-bottom: 2px;
      border-bottom: 2px solid transparent;
    }

    &:hover,
    &--active {
      span {
        border-color: $color-primary;
      }
    }
  }

  .mobile-control {
    display: none;
    padding: 20px;
  }

  .mobile-menu {
    display: none;
    position: fixed;
    top: 80px;
    width: 100%;
    background-color: $color-secondary;
    box-shadow: 0 10px 16px -8px rgba(0, 0, 0, 0.25);

    &__backdrop {
      position: absolute;
      top: 100%;
      height: 100vh;
      width: 100%;
      background-color: rgba(darken($color-secondary, 10%), 0.64);
    }

    a {
      display: block;
      line-height: 64px;
    }
  }

  @include mobile {
    box-shadow: 0 10px 16px -8px rgba(0, 0, 0, 0.25);

    & > a {
      display: none;
    }

    .logo {
      .language-switcher-link {
        display: none;
      }
    }

    .language-switcher-link {
      padding: 0 20px;
    }

    .mobile-control {
      display: block;
    }

    .mobile-menu {
      display: block;
    }
  }
}

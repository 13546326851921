@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.choose-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $color-white;

  .choose-page-title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 42.5px;
    line-height: 60px;
  }

  .container-choose {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .step.stepThree {
      .contant-background {
        align-items: center;

        button {
          font-size: 24px;
        }
      }

      @include mobile {
        .contant-background {
          height: 100px;
          background: none;
        }
      }
    }

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mobile {
        flex-direction: column-reverse;
      }

      .stepTwo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .animate_desktop {
          display: block;
          max-height: 200%;

          @include mobile {
            display: none;
          }
        }

        .animate_mobile {
          display: none;
          height: 320px;
          position: relative;

          svg {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%, -50%) !important;
            height: 600px !important;
            width: 300px !important;
          }

          @include mobile {
            display: block;
          }
        }
      }

      .contant-background {
        background: $color-pink;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        display: flex;
        justify-content: center;

        @media (max-width: 1300px) {
          width: 300px;
          height: 300px;
        }

        svg {
          width: 70vw !important;

          @include mobile {
            width: 100vw !important;
          }
        }
      }

      .grain {
        width: 39px;
        height: 40px;
        object-fit: cover;
      }

      .coffeeMachine {
        margin-top: 20px;
        width: 166px;
        height: 215px;
        object-fit: contain;

        @media (max-width: 1300px) {
          width: 80px;
          height: 148px;
        }
      }

      .description {
        display: flex;
        padding-top: 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        max-width: 400px;
        margin-top: -60px;

        .text {
          padding-top: 10px;
        }

        .number {
          min-width: 44px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid $color-white;
          border-radius: 50%;
          font-family: $font-secondary;
          font-style: normal;
          font-weight: bold;
          font-size: 35px;
          line-height: 49px;
          margin-right: 7px;
        }

        @media (max-width: 1300px) {
          width: 100%;
          font-size: 18px;
          line-height: 22px;
          margin-top: 0;
        }
      }

      .container-machines {
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      label {
        position: absolute;
        bottom: -40px;
        padding: 16px;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: $color-secondary;
          transform: translate(-50%, -50%);

          @include mobile {
            background-color: $color-pink;
          }
        }

        &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $color-primary;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: opacity 0.2s;
        }

        &.active {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .snow {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: -1;
  }

  @include mobile {
    overflow-x: hidden;

    .container-choose {
      display: block;
    }

    .snow {
      display: none;
    }
  }
}

@import 'src/styles/variables.scss';

.conditions-page {
  color: $color-white !important;

  h2 {
    word-break: break-word;
    hyphens: auto;
  }

  h4 {
    margin: 8px 0 24px;
    font-weight: normal;
  }

  p {
    white-space: pre-line;
  }

  a {
    color: $color-white !important;
  }
}

@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: $color-white;

  h1,
  h3,
  p,
  a {
    position: relative;
    z-index: 2;
  }

  h1 {
    margin-bottom: 32px;
    max-width: 580px;
  }

  h3 {
    margin-bottom: 20px;
    max-width: 580px;
  }

  p {
    margin-bottom: 56px;
    max-width: 580px;
    font-size: 23px;
  }

  .coffee-machine {
    position: fixed;
    right: -130px;
    bottom: -80px;
    max-width: 700px;
    max-height: 110vh;
    z-index: -1;
  }

  .snow {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: -1;

    &-mobile {
      display: none;
    }
  }

  @include mobile {
    justify-content: flex-start;
    padding-right: 16px;
    padding-left: 16px;

    h1 {
      margin-bottom: 12px;
      font-size: 38px;
      line-height: 40px;
    }

    h3 {
      max-width: 80%;
      margin-bottom: 16px;
      font-size: 20px;
    }

    p {
      margin-bottom: 40px;
      max-width: 60%;
      font-size: 16px;
    }

    .coffee-machine {
      max-height: 55vh;
      max-width: 100vw;
      right: -20px;
      bottom: -12px;
    }

    .snow {
      display: none;

      &-mobile {
        display: block;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        z-index: -1;
      }
    }
  }
}

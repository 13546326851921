@import 'src/styles/mixins.scss';

.game-container {
  touch-action: none;
  user-select: none;

  .game-orientation-warning {
    display: none;
  }

  @include landscape {
    &--mobile {
      & > * {
        display: none;
      }

      .game-orientation-warning {
        display: block;
      }
    }
  }
}

@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.info-page {
  h2 {
    margin-bottom: 48px;
    color: $color-white;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & > * {
      flex: 1;
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @include mobile {
    h2 {
      margin-bottom: 24px;
    }

    &__cards {
      & > * {
        flex-basis: calc(50% - 20px);
        margin: 0;

        &:nth-child(even) {
          margin-left: 20px;
        }

        &:nth-child(n + 3) {
          margin-top: 20px;
        }
      }
    }
  }
}

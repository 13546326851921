@import 'src/styles/mixins.scss';

.snow {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: -1;

  &-mobile {
    display: none;
  }
}

@include mobile {
  .snow {
    display: none;

    &-mobile {
      display: block;
      position: fixed;
      left: 0;
      bottom: -20px;
      right: 0;
      width: 100%;
      z-index: -1;
    }
  }
}

.base-layout--mobile {
  @include landscape {
    .snow,
    .snow-mobile {
      display: none;
    }
  }
}

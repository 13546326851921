@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.game-items {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5;
  pointer-events: none;

  &__item {
    position: absolute;
    transform: translate(-50%, -50%);
    transition: all linear;

    div {
      position: relative;
    }

    img {
      max-width: 64px;
      max-height: 64px;
    }
  }

  &__label {
    position: absolute;
    top: -12px;
    right: 4px;
    padding: 8px 0;
    width: 32px;
    height: 32px;
    background-color: $color-primary;
    border-radius: 12px 0;
    border-radius: 50% 50% 50% 0;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 700;
    color: $color-secondary;
    text-align: center;

    &--bonus {
      top: auto;
      right: 0;
      bottom: 4px;
    }
  }

  @include mobile {
    &__item {
      img {
        max-width: 48px;
        max-height: 48px;
      }
    }

    &__label {
      top: -16px;
      right: -6px;

      &--bonus {
        top: auto;
        right: -4px;
        bottom: -4px;
      }
    }
  }
}

@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.game-overlay {
  position: absolute;
  top: 64px;
  left: 40px;
  font-size: 36px;
  font-weight: bold;
  color: $color-white;

  &--started,
  &--finished {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--started {
    font-size: 96px;

    & > div {
      position: relative;

      & > * {
        &:last-child {
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    button {
      z-index: 6;
    }
  }

  &--finished {
    background-color: rgba($color-secondary, 0.64);
    font-size: 56px;
    z-index: 6;
  }

  &__actions {
    position: absolute;
    right: 10%;
  }

  &__success {
    display: flex;
    align-items: center;
    padding: 20px;
    height: 100%;

    & > div {
      flex: 1 0;

      &:first-child {
        padding: 20px;
        text-align: center;
      }

      &:last-child {
        margin: auto;
        padding: 20px;
        max-width: 576px;
        max-height: 100%;
        background: $color-secondary;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
        font-size: 16px;
        overflow-y: auto;
      }
    }
  }

  h3 {
    font-family: $font-secondary;
    font-size: 36px;
    line-height: 44px;
    color: $color-white;
  }

  p {
    margin-top: 12px;
    padding-top: 16px;
    max-width: 300px;
    border-top: 2px solid $color-white;
    font-size: 22px;
    font-weight: normal;
  }

  @include mobile {
    top: 40px;
    left: 20px;

    &--started {
      top: 0;
      left: 0;
    }

    &--finished {
      top: 0;
      left: 0;
    }

    &__actions {
      position: static;
      width: 100%;
      max-width: 300px;
      text-align: right;
    }

    &__success {
      width: 100%;
      flex-direction: column;

      & > div {
        &:first-child {
          flex: 0;
          padding: 0 0 20px;
          font-size: 40px;

          & > * {
            &:not(:last-child) {
              display: none;
            }
          }
        }
      }
    }
  }
}

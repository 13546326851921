@mixin mobile {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin landscape {
  @media only screen and (orientation: landscape) {
    @content;
  }
}

@import 'src/styles/variables.scss';

.contact-page {
  color: $color-white;

  h4 {
    margin: 8px 0 24px;
    font-weight: normal;
  }

  p + p {
    margin-top: 24px;
  }
}

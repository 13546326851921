@import 'src/styles/variables.scss';

svg {
  vertical-align: middle;

  & > g {
    fill: $color-primary;
    stroke: $color-primary;
  }
}

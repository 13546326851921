@import 'src/styles/variables.scss';

.containerForm {
  .textInput {
    display: flex;
    flex-direction: column;

    .error_form {
      border: 2px solid rgb(141, 33, 33) !important;
    }
  }

  h2 {
    margin-bottom: 9px;
  }

  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    &:first-of-type {
      margin: 0 0 37px 0;
    }
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;

    @media (max-width: 992px) {
      font-size: 14px;
      line-height: 17px !important;
    }
  }

  .Form {
    margin-top: 8px;
  }

  .checkbox label {
    font-size: 14px;
    line-height: 17px;

    a {
      color: $color-white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  input.text-input {
    height: 40px;
    background: $color-pink;
    border: 1.6px solid $color-white;
    border-radius: 10px;
    margin: 2.5px 0 20px 0;
    outline: none;
    padding: 8px;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 700;
    color: lighten($color-white, 2.5%);

    &:focus {
      background: $color-pink;
    }
  }

  button {
    display: block;
    margin-top: 32px;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 48px;
    font-size: 26px;

    &::after {
      right: 16px;
      width: 12px;
      height: 12px;
      border-right: 2px solid;
      border-bottom: 2px solid;
    }
  }

  .error {
    color: rgb(131, 30, 30);
    padding-bottom: 10px;
  }

  .error_form {
    input[type='checkbox']:checked + label:before,
    input[type='checkbox']:not(:checked) + label:before {
      border: 1px solid rgb(131, 30, 30) !important;
    }
  }

  .formItem.checkbox {
    display: flex;
    margin-bottom: 8.5px;
    position: relative;

    input[type='checkbox']:checked,
    input[type='checkbox']:not(:checked) {
      position: absolute;
      z-index: 5;
      opacity: 0;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    input[type='checkbox']:checked + label,
    input[type='checkbox']:not(:checked) + label {
      display: inline-block;
      position: relative;
      padding-left: 28px;
    }

    input[type='checkbox']:checked + label:before,
    input[type='checkbox']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 16px;
      height: 16px;
      background: $color-pink;
      border: 0.8px solid $color-white;
      box-sizing: border-box;
      border-radius: 1.6px;
    }

    input[type='checkbox']:checked + label:before,
    input[type='checkbox']:not(:checked) + label:before {
      border-radius: 2px;
    }

    input[type='checkbox']:checked + label:after,
    input[type='checkbox']:not(:checked) + label:after {
      content: '';
      position: absolute;
      -webkit-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    input[type='checkbox']:checked + label:after,
    input[type='checkbox']:not(:checked) + label:after {
      left: 2px;
      top: 4px;
      width: 10px;
      height: 4px;
      border-radius: 1px;
      border-left: 2px solid #eed490;
      border-bottom: 2px solid #eed490;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    input[type='checkbox']:not(:checked) + label:after {
      opacity: 0;
    }

    input[type='checkbox']:checked + label:after {
      opacity: 1;
    }
  }
}

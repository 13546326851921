.base-layout {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  & > div {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
